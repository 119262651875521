<template>
  <v-container class="d-flex justify-center align-center top-margin-correct">
    <div class="login-form-wrap">
      <ValidationObserver ref="loginFrmObserver" v-slot="{ invalid }">
        <v-form ref="loginFrm" @submit.prevent="login">
          <h2>로그인</h2>
          <v-card elevation="0" class="rounded-lg" outlined>
            <v-card-text>
              <ValidationProvider v-slot="{ errors }" name="이메일" rules="required|email">
                <v-text-field
                  v-model="loginFrm.email"
                  label="이메일"
                  placeholder="이메일을 입력하세요."
                  outlined
                  dense
                  :error-messages="errors"
                >
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" name="비밀번호" rules="required|verify_password">
                <v-text-field
                  v-model="loginFrm.password"
                  label="비밀번호"
                  placeholder="비밀번호를 입력하세요."
                  outlined
                  dense
                  :type="passwdShow ? 'text' : 'password'"
                  :append-icon="passwdShow ? 'mdi-eye' : 'mdi-eye-off'"
                  :error-messages="errors"
                  @click:append="passwdShow = !passwdShow"
                >
                </v-text-field>
              </ValidationProvider>
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit" :disabled="invalid" elevation="0" x-large block color="primary">로그인</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        <div class="actions">
          <NuxtLink to="/forgetPassword">비밀번호 찾기</NuxtLink>
          <NuxtLink to="/register">회원가입</NuxtLink>
        </div>
      </ValidationObserver>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  components: {},
  middleware: ['auth'],
  data() {
    return {
      passwdShow: false,
      loginFrm: {
        email: '',
        password: '',
      },
    }
  },
  mounted() {
    if (this.$route.query.mode === 'login') {
      this.$snackbar.showMessage({
        content: '로그인이 필요한 서비스입니다.',
        color: 'orange darken-4',
        delay: 3000,
      })
    }
  },
  unmounted() {},
  methods: {
    async login() {
      this.$nuxt.$loading.start()
      const vm = this
      await this.$auth
        .loginWith('laravelSanctum', {
          data: this.loginFrm,
        })
        .then((response) => {
          if (this.$auth.loggedIn && response.status === 200) {
            if (this.$route.query.redirect) {
              vm.$router.push(this.$route.query.redirect)
            } else {
              vm.$router.push('/')
            }
          } else {
            vm.$snackbar.showMessage({
              content: response.data.message,
              color: 'orange darken-4',
              delay: 3000,
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
      this.$nuxt.$loading.finish()
    },
  },
}
</script>

<style lang="scss" scoped>
h2 {
  margin-bottom: 10px;
  text-align: center;
  font-family: $font__kangwon;
  font-weight: normal;
}
.login-form-wrap {
  margin: 40px auto;
  max-width: 460px;
  width: 100%;
}
.prepend-button {
  .v-input__prepend-outer {
    margin-top: 0 !important;
  }
}

.actions {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  a + a {
    margin-left: 10px;
    &::before {
      content: '\b7';
      margin-right: 10px;
    }
  }
}
</style>
